<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4 pa-3">
            <psi-toolbar
                title="Invoices"
                color="secondary"
                enable-search
                :indicator="$_.get(invoicePage, 'total')"
                :filters="filters"
                :quicklinks="quicklinks"
                :actions="actions"
                actionMenuIcon="mdi-checkbox-multiple-marked-outline"
                :showActionMenu="selectedItems.length > 0"
                :view-option.sync="viewOption"
                :buttons="buttons"
                @filter-item="filterItem"
                @filter-clear-all="filterClearAll"
                @quicklink-action="quicklinkAction"
                @refresh="refresh"
                @process="processDialog = true"
            ></psi-toolbar>
            <v-dialog v-model="processDialog" max-width="600" persistent>
                <invoice-process
                    v-if="processDialog"
                    @close="processDialog = false"
                ></invoice-process>
            </v-dialog>

            <keep-alive>
                <component
                    :loading="loading"
                    :invoices="invoices"
                    :is="`invoice-${viewOption}`"
                    @item-selected="itemSelected"
                    @toggle-select-all="toggleSelectAll"
                    @action="invoiceAction"
                ></component>
            </keep-alive>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import InvoiceList from "@components/invoice/components/InvoiceList";
import InvoiceGrid from "@components/invoice/components/InvoiceGrid";
import InvoiceProcess from "@components/invoice/components/InvoiceProcess";

export default {
    name: "company-invoicing",
    components: {
        InvoiceList,
        InvoiceGrid,
        InvoiceProcess,
    },
    data() {
        return {
            page: {
                title: "Invoicing",
                icon: "mdi-receipt",
            },
            breadcrumbs: [
                {
                    text: "Invoicing",
                    disabled: true,
                    to: "#",
                },
            ],
            filters: this.$config("invoice.filters"),
            quicklinks: this.$config("invoice.quicklinks"),
            actions: this.$config("invoice.actions"),
            buttons: [
                {
                    icon: "mdi-credit-card-check-outline",
                    tooltip: "Process Paid Invoices",
                    event: "process",
                },
            ],
            selectedItems: [],
            viewOption: this.$vuetify.breakpoint.mobile ? "grid" : "list",
            processDialog: false,
        };
    },
    mounted() {
        this.filter();
    },
    computed: {
        ...mapGetters("Invoices", [
            "invoices",
            "invoiceFilters",
            "invoicePage",
            "loading",
        ]),
    },
    watch: {
        invoiceFilters: {
            immediate: true,
            handler(filter) {
                if (filter && Object.keys(filter).length) {
                    this.updateFilter(
                        "company",
                        this.$_.get(filter, "companies", [])
                    );
                    this.updateFilter(
                        "property",
                        this.$_.get(filter, "properties", [])
                    );
                }
            },
        },
    },
    methods: {
        ...mapActions("Invoices", ["getInvoices"]),
        refresh() {
            // Derive filters and issue filter orders call
            this.filter();
        },
        quicklinkAction(event) {
            switch (event.title) {
                // TODO: change from title based to name based
                case "Action":
                    // Clear out any set filters
                    this.resetFilters();
                    // Add only this filter from the quicklink
                    this.updateFilter("status", "Pending", "value");
                    // Get the result data
                    this.filterOrders();
                    break;
            }
        },
        itemSelected({ item, value }) {
            if (value) {
                // selected an item
                this.selectedItems.push(item);
            } else {
                // removed an item
                this.selectedItems.splice(
                    this.selectedItems.findIndex(
                        (elem) => elem.order_id === item.order_id
                    ),
                    1
                );
            }
        },
        toggleSelectAll({ items, value }) {
            if (value) {
                this.selectedItems = items;
            } else {
                this.selectedItems = [];
            }
        },
        invoiceAction(id, item) {
            console.log(id, item);
            switch (item.title) {
                case "View Invoice":
                    this.$router.push({
                        name: "Invoice",
                        params: { id },
                    });
                    break;
            }
        },
        /**
         * Run the result from the filter
         */
        filter() {
            this.getInvoices(this.getFilter());
        },
        // Reset Filters to default values
        resetFilters() {
            this.filters.forEach((item) => {
                this.updateFilter(item.name, item.default, "value");
            });
        },
        // update a filter item value on the filters data
        updateFilter(filterName, data, key = "items") {
            const index = this.filters.findIndex(
                (item) => item.name == filterName
            );
            if (index !== -1) {
                let filter = this.filters[index];
                filter[key] = data;
                this.filters.splice(index, 1, filter);
            }
        },
        // set a given item to a filter value
        filterItem(item) {
            this.updateFilter(item.name, item.value, "value");
            this.filter();
        },
        filterClearAll() {
            this.resetFilters();
            this.filter();
        },
        // get a filter structure for the given filters with values
        getFilter() {
            let filter = {};
            this.filters.forEach((item) => {
                if (this.$_.get(item, "value", "").toString().length) {
                    filter[item.name] = {
                        value: this.$_.get(item, "value", ""),
                    };
                }
            });
            return filter;
        },
    },
};
</script>

<style scoped>
</style>