var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "single-select": false,
      "item-key": "id",
      "show-select": "",
      headers: _vm.headers,
      items: _vm.invoices,
      "items-per-page": 10,
      loading: _vm.loading,
      "loading-text": "Loading ... please wait",
      "loader-height": 6,
      "hide-default-footer": _vm.loading
    },
    on: {
      "toggle-select-all": function($event) {
        return _vm.$emit("toggle-select-all", $event)
      },
      "item-selected": function($event) {
        return _vm.$emit("item-selected", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item.status",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-chip", {
              staticClass: "text-uppercase",
              attrs: { small: "", color: "warning darken-2" },
              domProps: { textContent: _vm._s(item.status) }
            })
          ]
        }
      },
      {
        key: "item.amount",
        fn: function(ref) {
          var item = ref.item
          return [_c("span", [_vm._v(_vm._s(_vm.$dollarFormat(item.amount)))])]
        }
      },
      {
        key: "item.due_amount",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", [_vm._v(_vm._s(_vm.$dollarFormat(item.due_amount)))])
          ]
        }
      },
      {
        key: "item.applicant",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "d-flex flex-column my-1" }, [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium alternate1--text text--darken-1"
                },
                [_vm._v(_vm._s(item.applicant.name))]
              ),
              _c("div", [
                _c("span", { staticClass: "font-weight-light" }, [
                  _vm._v("Type:")
                ]),
                _c("span", { staticClass: "font-weight-medium ml-1" }, [
                  _vm._v(_vm._s(item.applicant.type))
                ])
              ])
            ])
          ]
        }
      },
      {
        key: "item.orders",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              {
                staticClass: "text-xs-center",
                attrs: { small: "", color: "primary" }
              },
              [_vm._v(_vm._s(item.orders.length.toString().trim()))]
            )
          ]
        }
      },
      {
        key: "item.id",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "span",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Invoice",
                        params: { id: item.id }
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.id))]
                )
              ],
              1
            ),
            _c("psi-copy-button", {
              attrs: { value: item.id, label: "Invoice " }
            }),
            item.item_new
              ? _c(
                  "v-chip",
                  {
                    staticClass: "pa-1 ml-1 text-caption",
                    attrs: { color: "success darken-2", small: "" }
                  },
                  [_vm._v("new")]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("psi-action-drop-down", {
              attrs: {
                heading: "Actions - " + item.property.name,
                items: _vm.getActions()
              },
              on: {
                action: function($event) {
                  return _vm.$emit("action", item.id, $event)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }