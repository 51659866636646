export default {
    /**
     * Function getActions - gets an action list to execute against for the screening orders
     *
     * @param {*} item Order returned from order list
     */
    getActions() {
        return [
            {
                title: "View Invoice",
                icon: "mdi-receipt",
            },
            {
                title: "Print Invoice",
                icon: "mdi-adobe-acrobat",
            },
            {
                divider: true,
            },
            {
                title: "Fax Invoice",
                icon: "mdi-fax",
            },
            {
                title: "Send Invoice",
                icon: "mdi-email",
            },
        ];
    },
};
