var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4 pa-3" },
        [
          _c("psi-toolbar", {
            attrs: {
              title: "Invoices",
              color: "secondary",
              "enable-search": "",
              indicator: _vm.$_.get(_vm.invoicePage, "total"),
              filters: _vm.filters,
              quicklinks: _vm.quicklinks,
              actions: _vm.actions,
              actionMenuIcon: "mdi-checkbox-multiple-marked-outline",
              showActionMenu: _vm.selectedItems.length > 0,
              "view-option": _vm.viewOption,
              buttons: _vm.buttons
            },
            on: {
              "update:viewOption": function($event) {
                _vm.viewOption = $event
              },
              "update:view-option": function($event) {
                _vm.viewOption = $event
              },
              "filter-item": _vm.filterItem,
              "filter-clear-all": _vm.filterClearAll,
              "quicklink-action": _vm.quicklinkAction,
              refresh: _vm.refresh,
              process: function($event) {
                _vm.processDialog = true
              }
            }
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600", persistent: "" },
              model: {
                value: _vm.processDialog,
                callback: function($$v) {
                  _vm.processDialog = $$v
                },
                expression: "processDialog"
              }
            },
            [
              _vm.processDialog
                ? _c("invoice-process", {
                    on: {
                      close: function($event) {
                        _vm.processDialog = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "keep-alive",
            [
              _c("invoice-" + _vm.viewOption, {
                tag: "component",
                attrs: { loading: _vm.loading, invoices: _vm.invoices },
                on: {
                  "item-selected": _vm.itemSelected,
                  "toggle-select-all": _vm.toggleSelectAll,
                  action: _vm.invoiceAction
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }