<template>
    <v-row wrap v-if="invoices">
        <template v-for="(invoice, index) in invoices">
            <v-col cols="12" lg="3" md="4" :key="index">
                <v-card color="grey lighten-3">
                    <v-card-title
                        class="white--text text-subtitle-1 secondary darken-1 py-2"
                    >
                        <v-icon class="white--text mr-2">mdi-receipt</v-icon>
                        {{ $_.get(invoice, "property.name") }}
                        <v-spacer></v-spacer>
                        <psi-action-drop-down
                            :items="getActions()"
                            :heading="`Actions - ${invoice.property.name}`"
                            icon="mdi-dots-vertical"
                            @action="$emit('action', invoice.id, $event)"
                        ></psi-action-drop-down>
                    </v-card-title>
                    <v-card-text>
                        <v-card class="my-3 py-1">
                            <v-card-text>
                                <div class="d-flex">
                                    <span class="font-weight-regular"
                                        >Invoice Date:</span
                                    >
                                    <v-chip small color="primary" class="ml-3">
                                        {{ invoice.end_date }}</v-chip
                                    >
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        small
                                        :color="
                                            $_.get(
                                                invoice,
                                                'status.color',
                                                'warning darken-2'
                                            )
                                        "
                                    >
                                        {{ $_.get(invoice, "status") }}
                                    </v-chip>
                                </div>
                                <v-divider class="mt-2"></v-divider>
                                <div class="d-flex">
                                    <v-icon class="accent--text"
                                        >mdi-domain</v-icon
                                    >
                                    <v-card-title
                                        class="font-weight-regular text-subtitle-2 py-2"
                                    >
                                        <span
                                            class="ml-2 text-body-2 font-weight-bold"
                                            >{{
                                                $_.get(
                                                    invoice,
                                                    "property.company.name"
                                                )
                                            }}</span
                                        >
                                    </v-card-title>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-receipt</v-icon
                                            >
                                        </template>
                                        <span>Invoice #</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span>
                                        <router-link
                                            :to="{
                                                name: 'Invoice',
                                                params: { id: invoice.id },
                                            }"
                                            >{{ invoice.id }}</router-link
                                        >
                                        <!-- <a href="">{{ item.order_id }}</a> -->
                                    </span>
                                    <psi-copy-button
                                        :value="invoice.id"
                                        label="Invoice #"
                                    ></psi-copy-button>
                                    <v-chip
                                        v-if="invoice.overdue"
                                        color="error darken-1"
                                        small
                                        class="pa-2 ml-1 text-caption"
                                        >Overdue: {{ invoice.overdue }}</v-chip
                                    >
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-clipboard-text-search</v-icon
                                            >
                                        </template>
                                        <span>Orders</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="font-weight-regular"
                                        >Orders:</span
                                    >
                                    <v-chip class="ml-1" color="primary">{{
                                        invoice.orders.length
                                    }}</v-chip>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-currency-usd</v-icon
                                            >
                                        </template>
                                        <span>Invoice Amount</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="font-weight-regular"
                                        >Invoiced:</span
                                    >
                                    {{ $dollarFormat(invoice.amount) }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-currency-usd</v-icon
                                            >
                                        </template>
                                        <span>Balance</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="font-weight-regular"
                                        >Balance:</span
                                    >
                                    {{ $dollarFormat(invoice.due_amount) }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>
<script>
import lib from "../library";

export default {
    name: "invoice-grid",
    components: {},
    props: {
        invoices: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        getActions() {
            return lib.getActions();
        },
    },
};
</script>

<style scoped>
</style>