<template>
    <v-data-table
        :single-select="false"
        item-key="id"
        show-select
        :headers="headers"
        :items="invoices"
        :items-per-page="10"
        :loading="loading"
        loading-text="Loading ... please wait"
        :loader-height="6"
        :hide-default-footer="loading"
        @toggle-select-all="$emit('toggle-select-all', $event)"
        @item-selected="$emit('item-selected', $event)"
    >
        <template v-slot:item.status="{ item }">
            <v-chip
                small
                class="text-uppercase"
                color="warning darken-2"
                v-text="item.status"
            ></v-chip>
        </template>
        <template v-slot:item.amount="{ item }">
            <span>{{ $dollarFormat(item.amount) }}</span>
        </template>
        <template v-slot:item.due_amount="{ item }">
            <span>{{ $dollarFormat(item.due_amount) }}</span>
        </template>

        <template v-slot:item.applicant="{ item }">
            <div class="d-flex flex-column my-1">
                <span
                    class="font-weight-medium alternate1--text text--darken-1"
                    >{{ item.applicant.name }}</span
                >
                <div>
                    <span class="font-weight-light">Type:</span>
                    <span class="font-weight-medium ml-1">{{
                        item.applicant.type
                    }}</span>
                </div>
            </div>
        </template>
        <template v-slot:item.orders="{ item }">
            <v-chip small color="primary" class="text-xs-center">{{
                item.orders.length.toString().trim()
            }}</v-chip>
        </template>
        <template v-slot:item.id="{ item }">
            <span>
                <router-link
                    :to="{
                        name: 'Invoice',
                        params: { id: item.id },
                    }"
                    >{{ item.id }}</router-link
                >
            </span>
            <psi-copy-button
                :value="item.id"
                label="Invoice "
            ></psi-copy-button>
            <v-chip
                v-if="item.item_new"
                color="success darken-2"
                small
                class="pa-1 ml-1 text-caption"
                >new</v-chip
            >
        </template>
        <template v-slot:item.actions="{ item }">
            <psi-action-drop-down
                :heading="`Actions - ${item.property.name}`"
                :items="getActions()"
                @action="$emit('action', item.id, $event)"
            ></psi-action-drop-down>
        </template>
    </v-data-table>
</template>
<script>
import lib from "../library";
export default {
    name: "invoice-list",
    components: {},
    props: {
        invoices: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Invoice #",
                    align: "start",
                    sortable: true,
                    value: "id",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Status",
                    align: "start",
                    sortable: true,
                    value: "status",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Property",
                    align: "start",
                    sortable: true,
                    value: "property.name",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Company",
                    align: "start",
                    sortable: true,
                    value: "property.company.name",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },

                {
                    text: "Orders",
                    align: "center",
                    sortable: false,
                    value: "orders",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },

                {
                    text: "Invoice Date",
                    align: "center",
                    sortable: true,
                    value: "end_date",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Invoiced",
                    align: "end",
                    sortable: true,
                    value: "amount",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Balance",
                    align: "end",
                    sortable: true,
                    value: "due_amount",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actions",
                    align: "center",
                    sortable: false,
                    value: "actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    methods: {
        getActions() {
            return lib.getActions();
        },
    },
};
</script>

<style scoped>
</style>