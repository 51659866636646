<template>
    <psi-dialog
        heading-color="accent darken-1"
        title="Process Paid Invoices"
        scrollable
        icon="mdi-magnify"
        @close="$emit('close')"
    >
        <div style="height: 320px">
            <v-autocomplete
                v-model="model"
                clearable
                :items="items"
                :loading="loading"
                :search-input.sync="search"
                prepend-inner-icon="mdi-magnify"
                autofocus
                solo
                color="accent"
                hide-no-data
                hide-selected
                item-text="description"
                item-value="id"
                label="Search"
                placeholder="Search by ID or Property"
                :prepend-icon="
                    $vuetify.breakpoint.mobile ? '' : 'mdi-database-search'
                "
                return-object
                @click:clear="initialize"
            >
                <template v-slot:item="data">
                    <v-list-item-avatar>
                        <v-icon size="32" class="accent--text text--darken-2"
                            >mdi-home-city</v-icon
                        >
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title
                            v-html="data.item.property.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                            >Invoice #{{ data.item.id }} | Due:
                            <strong>{{
                                $dollarFormat(data.item.due_amount)
                            }}</strong>
                            <span class="hidden-sm-and-down">
                                | Due Date:
                                {{ data.item.due_date }}
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                    <span class="text-uppercase text-body-2 text-lg-subtitle-2"
                        ><v-icon
                            :small="!$vuetify.breakpoint.mobile"
                            :x-small="$vuetify.breakpoint.mobile"
                            class="accent--text text--darken-2 mr-1 mt-n1"
                            >mdi-home-city</v-icon
                        ><span class="hidden-sm-and-down">{{
                            data.item.property.name
                        }}</span>
                        # {{ data.item.id }}
                    </span>
                </template>
            </v-autocomplete>

            <div v-if="model" class="mt-n2">
                <div
                    class="d-flex align-center mx-auto"
                    :style="{ width: width }"
                >
                    <v-switch
                        label="Paid in Full"
                        value="paid"
                        v-model="status"
                        color="accent"
                    ></v-switch>
                    <v-spacer></v-spacer>
                    <span class="text-lg-h6 text-subtitle-1">{{
                        $dollarFormat(model.due_amount)
                    }}</span>
                </div>
                <div
                    class="d-flex align-center mx-auto"
                    :style="{ width: width }"
                >
                    <v-switch
                        label="Partial Amount"
                        value="partial"
                        color="accent"
                        v-model="status"
                        :disabled="amount === 0"
                    ></v-switch>
                    <v-spacer></v-spacer>
                    <div style="width: 120px">
                        <psi-form-currency-field
                            name="amount"
                            id="amount"
                            v-model="amount"
                            hide-details
                        ></psi-form-currency-field>
                    </div>
                </div>
                <v-alert
                    :prominent="!$vuetify.breakpoint.mobile"
                    icon="mdi-credit-card-check"
                    border="top"
                    color="accent"
                    dark
                    v-if="status"
                >
                    {{ paymentMessage }}
                </v-alert>
            </div>
        </div>

        <template v-slot:actions>
            <v-btn class="mr-8" @click.stop="$emit('close')">
                <v-icon class="mr-1">mdi-close</v-icon>
                <span class="hidden-sm-and-down">Cancel</span>
            </v-btn>
            <v-btn
                color="accent"
                class="px-4"
                @click.stop="
                    $emit('save');
                    initialize();
                "
                :disabled="!status"
            >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                <span class="hidden-sm-and-down">Save and Close</span>
            </v-btn>
            <v-btn
                color="accent"
                class="px-4"
                @click.stop="
                    $emit('continue');
                    initialize();
                "
                :disabled="!status"
            >
                <v-icon class="mr-1">mdi-content-save-move</v-icon>
                <span class="hidden-sm-and-down">Save and Continue</span>
            </v-btn>
        </template>
    </psi-dialog>
</template>
<script>
import api from "../api";

export default {
    name: "invoice-process",
    components: {},
    props: {},
    data() {
        return {
            model: null,
            items: [],
            search: null,
            loading: false,
            status: null,
            amount: 0,
        };
    },
    computed: {
        width() {
            return this.$vuetify.breakpoint.mobile ? "320px" : "400px";
        },
        paymentMessage() {
            const paymentStatus =
                this.status === "paid" ? "paid in full" : "partial payment";
            const paymentAmount =
                this.status === "paid"
                    ? this.$dollarFormat(this.model.due_amount)
                    : this.$dollarFormat(this.amount);
            return `Setting invoice #${this.model.id} for ${this.model.property.name} as ${paymentStatus} in amount ${paymentAmount}`;
        },
    },
    watch: {
        search(val) {
            val && this.searchInvoices(val);
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        initialize() {
            this.model = null;
            this.status = null;
            this.amount = 0;
        },
        async searchInvoices(term) {
            this.loading = true;
            this.items = await api.searchInvoices(term);
            this.loading = false;
        },
    },
};
</script>

<style scoped>
</style>